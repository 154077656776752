<script>

import DealService from "@/services/deal-service";
import CoinInfoFetcherService from "@/services/CoinInfoFetcherService";
import Coin from "@/state/entities/coin";
import Profit from "@/components/profit";
import moment from "moment";

export default {
  components: {
    Profit
  },
  data() {
    return {
      lastClosedDealsAmount: 7,
      result: [],
      dataLoaded: false,
    };
  },
  async mounted() {
    let result = ((await DealService.lastClosedDeals(this.lastClosedDealsAmount)).data).data;

    for (const row of result) {
      let storageCoin = localStorage.getItem(row.quote_asset.toUpperCase());
      if (!storageCoin) {
        try {
          let coinInfoResponse = await CoinInfoFetcherService.coinInfo(row.quote_asset.toUpperCase());
          storageCoin = JSON.stringify(coinInfoResponse.data);
          localStorage.setItem(coinInfoResponse.data.code, storageCoin);
        } catch (error) {
          console.log(error);
        }
      }
      let coinInfo = JSON.parse(storageCoin);
      const coin = new Coin();
      coin.title = coinInfo.title;
      coin.code = coinInfo.code;
      coin.image_path = coinInfo.image_path;
      row.coin = coin;
    }

    this.result = result;
    this.dataLoaded = true;
  },
  methods: {
    formatClosedDate (deal) {
      if (deal.closed_at) {
        return moment(deal.closed_at).format('YYYY-MM-DD hh:mm')
      }
      return null;
    }
  },
}
</script>

<template>
  <div class="card">
    <div class="card-header border-1 align-items-center d-flex bg-soft-info bg-opacity-10">
      <h4 class="card-title mb-0 flex-grow-1">{{ $t("t-last-completed-deals") }}</h4>
    </div>
    <div class="card-body" style="min-height: 250px;" >
      <div class="text-center" v-show="dataLoaded === false" style="margin-top:90px;">
      <div class="spinner-border text-danger text-center" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
      <div class="text-center" v-show="dataLoaded === true && result.length === 0" style="opacity: 0.5;">
        <h1>{{ $t("t-no-data") }}</h1>
        <span class="ri-clipboard-line ri-8x"></span>
      </div>
      <ul class="list-group list-group-flush border-dashed mb-0 mt-0 pt-0">
        <li class="list-group-item px-0" v-for="deal in result" :key="deal._id">
          <div class="d-flex">
            <div class="flex-shrink-0 avatar-xs">
                <span class="avatar-title bg-light p-1 rounded-circle">
                  <img :src="deal.coin ? deal.coin.image_path : ''" class="avatar-xxs" alt=""/>
                </span>
            </div>
            <div class="flex-grow-1 ms-2">
              <h6 class="mb-1">{{deal.coin ? deal.coin.title: ''}}</h6>
              <p class="fs-12 mb-0 text-muted">
                {{deal.quote_asset}}
              </p>
            </div>
            <div class="flex-shrink-0 text-end">
                <profit :profit="deal.profit"></profit>
              <p class="text-muted fs-12 mb-0">
                {{ deal.closed_at ? formatClosedDate(deal): '' }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="card-footer text-end bg-soft-info bg-opacity-10">
      <a href="/deals">{{ $t('t-all-deals-history') }}</a>
    </div>
  </div>
</template>
