<script>
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([]);

import Layout from "./../layouts/main";
import appConfig from "./../../app.config.json";
import OverallProfit from "@/components/dashboard/OverallProfit";
import TodaysProfit from "@/components/dashboard/TodaysProfit";
import ThisMonthProfit from "@/components/dashboard/ThisMonthProfit";
import DailyProfitChart from "@/components/dashboard/DailyProfitChart.vue";
import MontlyProfitChart from "@/components/dashboard/MontlyProfitChart";
import PurchaseStatistics from "@/components/dashboard/PurchaseStatistics";
import LastSuccessDeals from "@/components/dashboard/LastClosedDeals";
import ExchangeConnection from "@/state/entities/exchangeConnection";

export default {
  components: {
    DailyProfitChart,
    MontlyProfitChart,
    LastSuccessDeals,
    PurchaseStatistics,
    OverallProfit,
    TodaysProfit,
    ThisMonthProfit,
    Layout,
  },
  page: {
    title: "Dashboard",
    meta: [{
      name: "Dashboard panel",
      content: appConfig.description
    }],
  },
  async mounted() {
    (await ExchangeConnection.api().get('/exchange-connection'));
    this.showWizardLink = ExchangeConnection.all().length === 0;
  },
  data() {
    return {
      showWizardLink: false,
      items: [],
    };
  },
};
</script>

<template>
  <Layout>

    <div class="row">
      <div class="col-xl-6">
        <div class="card crm-widget">
          <div class="card-header border-bottom-dashed d-flex align-items-center bg-soft-info bg-opacity-10" style="padding: 0.5rem 1rem;">
            <h4 class="card-title mb-0 flex-grow-1">{{ $t("t-profit") }}</h4>
          </div>
          <div class="card-body" style="padding: 0.5rem 1rem;">
            <div class="row row-cols-md-3 row-cols-3">
              <TodaysProfit></TodaysProfit>
              <ThisMonthProfit></ThisMonthProfit>
              <OverallProfit></OverallProfit>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6" v-if="showWizardLink">
        <div class="card">
          <div class="card-body p-0">
            <div class="row align-items-end">
              <div class="col-sm-12">
                <div class="p-3">
                  <p class="fs-16 lh-base">Launch your trading bot and automate profit</p>
                  <div class="mt-3"><a href="/setup-wizard" class="btn btn-success">Start trading!</a></div>
                </div>
              </div>
            </div>
            </div>
        </div>
    </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-sm-12">
          <DailyProfitChart></DailyProfitChart>
      </div>
      <div class="col-lg-6 col-sm-12">
        <MontlyProfitChart></MontlyProfitChart>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-sm-12">
        <PurchaseStatistics></PurchaseStatistics>
      </div>
      <div class="col-lg-4 col-sm-12">
        <LastSuccessDeals></LastSuccessDeals>
      </div>
    </div>
  </Layout>
</template>
